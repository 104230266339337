<template>
  <div
    class="product-card__over-hidden"
    :style="{ '--transform-auto-wrap': `translateY(${transformAutoWrap}px)`, '--auto-wrap-height': `${rowHeight}px` }"
  >
    <div
      class="product-card__auto-wrap"
    >
      <template
        v-for="(item, index) in rows"
        :key="index"
      >
        <!-- 强制换行 -->
        <div
          v-if="item.type === 'br'"
          class="product-card__auto-break"
          data-type="br"
        ></div>

        <!-- 添加 | 分割标签 -->
        <!-- <div
          v-else-if="item.type === 'line'"
          class="product-card__auto-line"
        ></div> -->

        <div
          v-else-if="item.type === 'slot'"
          class="product-card__auto-rank"
          data-type="ranking" 
          :data-tag-index="(item.labelIndex || 0) + 1"
        >
          <ProductCardHorseRaceSlideItem
            :item-data="item"
          >
            <slot></slot>
          </ProductCardHorseRaceSlideItem>
        </div>

        <div
          v-else-if="item.type !== 'gap' && item.type !== 'line'"
          :class="[
            'product-card__auto-label',
            {
              'product-card__auto-label_horse': item.isCccxHorseLabel,
            }
          ]"
          data-type="label"
          :data-real-type="item.type"
          :data-tag-id="item.type === 'star' ? item?.starData?.tagId || '' : item?.textData?.tagId || ''"
          :data-tag-index="item?.labelIndex + 1"
          :data-tag-content="getDataTagContent(item)"
          :data-tag-appTraceInfo="item?.textData?.appTraceInfo || item?.starData?.appTraceInfo"
        >
          <div
            v-if="rows[index - 1] && rows[index - 1].type === 'gap'"
            class="product-card__auto-gap"
          ></div>
          <div
            v-else-if="isMounted"
            class="product-card__auto-line"
          ></div>
          <div
            v-else
            class="product-card__auto-empty"
          >
          </div>

          <ProductCardHorseRaceSlideItem
            :item-data="item"
          >
            <slot></slot>
          </ProductCardHorseRaceSlideItem>
        </div>
      </template>
    </div>
  </div>
</template>

<script name="ProductCardAutoWrap" setup>
import ProductCardHorseRaceSlideItem from './ProductCardHorseRaceSlideItem.vue'
import { toRefs } from 'vue'


const props = defineProps({
  rows: {
    type: Array,
    default: () => [],
  },
  rowHeight: {
    type: Number,
    default: 0,
  },
  highLightRow: {
    type: Number,
    default: 0,
  },
  isMounted: {
    type: Boolean,
    default: false,
  },
})

const { rows, rowHeight, highLightRow, isMounted } = toRefs(props)
const transformAutoWrap = - highLightRow.value * rowHeight.value

// const getSlideItemStyle = (index) => {
//   if (isMounted.value) {
//     return {}
//   }

//   // 首次渲染需要展示两个标签，其余隐藏，待渲染计算完毕才展示
//   return { visibility: index <= 2 ? 'visible' : 'hidden' }
// }

const getDataTagContent = (item) => {
  // isCccxHorseLabel: 是cccx配置的赛马标签，才报这个埋点
  if (item.type === 'text' && item.isCccxHorseLabel) {
    return item?.textData?.labelLangEn?.replace(/\s/g, '_')?.toLowerCase() || ''
  } else if (item.type === 'star') {
    return ['star', item?.starData?.comment_rank_average || '', item?.starData?.comment_num || ''].join('_')
  }
}

</script>

<style lang="less">
.product-card__over-hidden {
  --single-star-margin-right: 1px;
  // --single-star-font-weight: 600;

  --gap-line-width: 6px;
  --hack-margin: 12px;
  --hack-fill: 6px;
  --negative-hack-margin: calc(var(--hack-margin) * -1);

  height: var(--auto-wrap-height);
  overflow: hidden; //隐藏多余的节点，只展示轮播中的滚动行
  width: calc(100% + var(--hack-fill));
  margin-left: var(--negative-hack-margin);
}

.product-card__auto-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transform: var(--transform-auto-wrap, translateY(0));

  .product-card__auto-break {
    flex-basis: 100%;
  }

  .product-card__auto-line {
    border-left: 1px solid #D9D9D9;
    width: 0;
    height: 10px;
    margin: 0 var(--gap-line-width);
  }

  .product-card__auto-gap {
    border-left: 1px solid #D9D9D9;
    width: 0;
    height: 10px;
    margin: 0 var(--gap-line-width);
  }

  .product-card__auto-empty {
    border-left: 1px solid transparent;
    width: 0;
    height: 10px;
    margin: 0 var(--gap-line-width);
  }

  .product-card__auto-rank {
    height: var(--auto-wrap-height);
    line-height: var(--auto-wrap-height);
    width: 100%;
    padding-left: var(--hack-margin);
  }

  .product-card__auto-label {
    display: flex;
    align-items: center;
    height: var(--auto-wrap-height);
    line-height: var(--auto-wrap-height);
    font-size: 14px;
    overflow: hidden;
  }
}
</style>
