<template>
  <ProductCardSellingPropositionStar 
    v-if="type === 'star'"
    :show-average="true"
    :star-data="itemData.starData"
  />
  <ProductCardSellingPropositionText
    v-else-if="['text', 'userVoucher'].includes(type)"
    :style="style"
    :font-style="fontStyle"
    :text="text"
    :icon="icon"
    :sui-icon="suiIcon"
  /> 
  <slot v-else-if="type === 'slot'"></slot>
</template>

<script name="ProductCardHorseRaceSlideItem" setup>

import ProductCardSellingPropositionText from './ProductCardSellingPropositionText.vue'
import ProductCardSellingPropositionStar from './ProductCardSellingPropositionStar.vue'

const props = defineProps({
  itemData: {
    type: Object,
    default() {
      return {
        type: ''
      }
    }
  }
})

const type = props?.itemData?.type ?? ''
const style = props?.itemData?.style ?? {}
const text = props?.itemData?.textData?.text ?? ''
const fontStyle = props?.itemData?.textData?.fontStyle ?? ''
const icon = props?.itemData?.textData?.icon ?? ''
const suiIcon = props?.itemData?.textData?.suiIcon ?? ''
</script>


<style lang="less" scoped>
.product-card__selling-proposition-swiper {
  --swiper-container-height: 0.42rem;
  height: var(--swiper-container-height, 0.42rem);
}
.product-card__selling-proposition-container {
  margin: 1px 0;
}
</style>
